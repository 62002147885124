.gyle-management {
  margin: 20px;
  .text-error {
    color: rgb(199, 0, 0);
  }
  .form-group.gyle-name {
    input.form-control {
      max-width: 63ch;
    }
  }
  .form-group.date-time {
    input.form-control {
      width: 19ch; // "YYYY-MM-DD hh:mm" = 16 characters. Plus a few extra for padding.
      float: left;
      font-family: monospace;
    }
    button {
      float: left;
      margin-left: 4ch;
    }
    .form-text {
      clear: both;
      max-width: 80ch;
    }
  }
  .form-group.temperature-profile {
    background-color: unset; // needed due to unfortunate conflict with TemperatureProfile page
    textarea {
      width: 19ch;
      font-family: monospace;
    }
    .form-text {
      clear: both;
      max-width: 80ch;
    }
  }
  .form-group.mode {
    select.form-control {
      max-width: 17ch;
    }
    input.form-control {
      max-width: 10ch;
    }
    .col.select-mode {
      flex-grow: 0;
      min-width: 20ch;
    }
    .col.t-hold.hidden {
      visibility: hidden;
    }
    .form-text {
      clear: both;
      max-width: 41ch;
    }
    label {
      white-space: nowrap;
    }
  }
  .row.footer-buttons {
    margin-top: 20px;
  }


  // Standard placeholder colour isn't much lighter than our input text colour. Lighten a tad.
  // TODO: Move to site wide SCSS file.
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b7b7b7;
    opacity: 1; /* Firefox */
  }
}
