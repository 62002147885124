body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// --------------- My additions --------------

body,
html {
  background-image: url('background-tile.jpg');
  background-repeat: repeat;
}

h3,
h2,
h1 {
  color: #444;
}

.navbar {
  box-shadow: 0 5px 16px -8px #000;
  &.navbar-light {
    .navbar-brand {
      color: #da4526;
      padding-bottom: 0.425rem;
      > img {
        width: 25px;
        height: 25px;
        margin: 3px 4px 0 0;
      }
    }
  }
}
