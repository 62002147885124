.status-page {
  > p {
    margin: 20px;
  }
  > pre {
    padding: 10px;
    background-color: #f8f8f85e;
    color: #666;

    .string {
      color: #ff00fb;
    }
    .number {
      color: #03771a;
    }
    .boolean {
      color: #00c3ff;
    }
    .null {
      color: #00c3ff;
    }
    .key {
      color: #026fec;
    }
  }
}
