.email-test {
  margin: 20px;
  .text-error {
    color: rgb(199, 0, 0);
    margin-left: 1em;
  }
  .form-group {
    display: flex;
  }
  .form-label {
    min-width: 8em;
  }
  .form-control {
    &.formNoRetry {
      width: 2em;
    }
  }
  .btn {
    margin-top: 20px;
  }
}
