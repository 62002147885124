.home {
  &.none-found {
    padding: 70px 0;
    text-align: center;

    /* Fade in as workaround to the flash os "None found" while waiting for ajax response.
    TODO: Fix properly. */
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
  }

  h3 {
    margin: 1rem 0 -0.25rem;
  }

  /* Clear floats after the columns */
  .chamber-cards:after {
    content: '';
    display: table;
    clear: both;
  }

  // .bg-yellow {
  //   background-color: rgb(0, 238, 255);
  // }
  // .bg-pink {
  //   background-color: pink;
  // }

  $gauge-height: 220px;
  .gauge-card {
    // background-color: green;
    text-align: center;
    width: $gauge-height;
    margin: 10px auto 20px;
  }
  #container-1,
  #container-2 {
    height: $gauge-height;
  }
  @media (min-width: 1281px) {
    $gauge-height: 350px;
    .gauge-card {
      // background-color: green;
      text-align: center;
      width: $gauge-height;
      margin: 10px auto 20px;
    }
    #container-1,
    #container-2 {
      height: $gauge-height;
    }
  }

  .instruction {
    margin-top: -0.5rem;
  }

  .highcharts-figure,
  .highcharts-data-table table {
    min-width: 310px;
    max-width: 500px;
    margin: 1em auto;
  }

  .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }
  .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }
  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }
  .highcharts-data-table td,
  .highcharts-data-table th,
  .highcharts-data-table caption {
    padding: 0.5em;
  }
  .highcharts-data-table thead tr,
  .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }

  .highcharts-tracker > .highcharts-label > .highcharts-data-label-box {
    fill: #f6f6f6;
  }
  .highcharts-gauge-series {
    > .highcharts-dial,
    > .highcharts-pivot {
      fill: #333;
    }
  }
}
