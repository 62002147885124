.temperature-profile {
  background-color: #fff;

  .header {
    padding-top: 10px;

    display: flex;
    .item {
      flex-grow: 1;
    }
    .item + .item {
      margin-left: 2%;
    }

    .item.button {
      margin-top: 20px;
      text-align: center;
      border-radius: 6px;
      .btn {
        margin-bottom: 0.2em;
        padding: 0.075rem 0.75rem;
      }
    }
  }

  div.instructions {
    font-size: 12px;
    color: rgb(102, 102, 102);
    text-align: center;

    h2 {
      font-weight: normal;
      font-size: 16px;
      color: rgb(51, 51, 51);
      margin: 5px;
    }

    p {
      margin: 0;
    }
  }

  .highcharts-figure {
    margin: 0;
    #profile-chart-ct {
      width: 100%;
      // 120px is the navbar and instructions height.
      // TODO: Dynamically measure this height rather than hard-coding.
      height: -webkit-calc(100vh - 120px);
      height: -moz-calc(100vh - 120px);
      height: calc(100vh - 120px);
    }
  }
}
