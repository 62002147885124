#app-wrap > .error {
  margin: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ------------------------------ My additions ------------------------------

.toast {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  &.success {
    margin-top: 10px;
    max-width: 180px;
    .toast-header {
      padding: 0.75rem 0.75rem;
      .mr-auto {
        &::before {
          vertical-align: middle;
          font-size: 200%;
          margin-right: 10px;
          color: #007bff;
          content: '\002713';
        }
      }
    }
  }
  &.error {
    margin-top: 2px;
    max-width: 280px;
    .toast-header {
      .mr-auto {
        &::before {
          vertical-align: middle;
          font-size: 180%;
          margin-right: 10px;
          color: #b80000;
          content: '\0026A0';
        }
      }
    }
  }
}
